body {
  margin: 0;
  font-family: 'Eina-Bold', Arial, sans-serif;
  background: #000; /* Black background */
  overflow: hidden; /* Prevent scrolling */
}

/* Import the font */
@font-face {
 font-family: 'Eina-Bold';
 src: url('/public/fonts/eina01-bold-webfont.woff2') format('woff2'),
      url('/public/fonts/eina01-bold-webfont.woff') format('woff');
 font-weight: normal;
 font-style: normal;

}
.mobile-only {
  display: none;
}
.grid-item {
  cursor: zoom-in;
}
.grid-item.excluded {
  cursor: help;
}
.grid-item.link-only {
  cursor: help;
}

body {
 margin: 0;
 font-family: Arial, sans-serif;
 background: #000; /* Black background */
 overflow: hidden; /* Prevent scrolling */
}

.question {
  cursor: help;
}

/* Main Container */
.main-container {
 position: relative;
 height: 100vh;
 width: 100vw;
 overflow: hidden;
}

/* Floating Video */
.floating-video {
 position: absolute;
 top: 50%;
 left: 50%;
 width: 25%;
 transform: translate(-50%, -50%);
 z-index: 10;
 pointer-events: none;
 mix-blend-mode: difference;
 animation: scale-to-500 15s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes scale-to-500 {
 0% {
   transform: translate(-50%, -50%) scale(0);
 }
 100% {
   transform: translate(-50%, -50%) scale(6);
 }
}

.floating-video video {
 width: 100%;
 height: auto;
 object-fit: cover;
}

/* Key Overlay Styling */
.key-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 15;
  overflow: auto;
  text-align: center;
  cursor: zoom-out;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Arial', sans-serif;
  z-index: 15;
  cursor: pointer;
}

.countdown {
  font-size: clamp(9.3rem, 5vw, 4rem);
  font-weight: bold;
  color: red;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-style: italic;
  line-height: 1em;
  margin-top: .1em;
  cursor: default;
}

.countdown-description {
  font-size: 1.2rem;
  color: white;
  margin-top: 1rem;
  font-family: 'Eina-Bold', Arial, sans-serif;
  text-align: center;
  font-style: italic;
  display: inline-block;
  transform: scaleX(1.5); /* Adjust this value */
}


.colon {
  transition: opacity 0.5s ease;
}

.colon.hidden {
  opacity: 0;
}

.colon.visible {
  opacity: 1;
}

.overlay-content {
  font-size: 2em;
  color: white;
  text-align: center;
}


.overlay-content {
  background: transparent;
  padding: 20px;
  color: #fff;
  font-family: 'Eina-Bold', Arial, sans-serif;
  text-align: center;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.overlay-content h1 {
  font-size: clamp(9.3rem, 5vw, 4rem);
  font-weight: bold;
  color: red;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-style: italic;
  line-height: 1em;
  margin-top: 0;
  cursor: default;
}

.overlay-content h2 {
  font-size: clamp(9rem, 5vw, 4rem);
  font-weight: bold;
  color: red;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-style: italic;
  line-height: 1em;
  margin-top: 0;
  cursor: default;
}

.overlay-content h3 {
  font-size: clamp(3rem, 5vw, 3rem);
  font-weight: bold;
  color: red;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-style: italic;
  line-height: 1em;
  margin-top: 1em;
  cursor: default;
}

.overlay-content h4 a  {
  color: red;
  font-size: 7em;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Eina-Bold';
  font-style: italic;
  line-height: .6em;
  margin-top: 0;
}

.copy-button {
  background: transparent;
  font-size: clamp(9rem, 5vw, 4rem);
  font-weight: bold;
  color: red !important;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-style: italic;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  transition: color 0.2s ease;
  text-decoration: underline;
  margin: 0 auto 10px;
}

.copy-button:hover {
  color: red;
  text-decoration: none;
}

.tooltip {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0.9;
  pointer-events: none;
}

.wrap {
  overflow-wrap: anywhere;
  text-transform: none !important;
}
.alpha {
  font-size: 0.75em;
}

.meta {
  display: inline-block;
  transform: scaleX(1.5); /* Adjust this value */
}

.manifold {
  font-size: 0.8em;
}

.jpl {
  display: inline-block;
  transform: scaleX(2.2); /* Adjust this value */
}

.overlay-content h4 a:hover {
  text-decoration: underline;
}
.overlay-content p {
  font-size: clamp(0.8rem, 2.5vw, 0.5rem);
  line-height: 1.5;
  font-weight: 100;
  font-style: italic;
  color: red;
  font-family: monospace;
  cursor: default;
  text-transform: uppercase;
}

.overlay-content a {
  color: red;
  text-decoration: none;
  
}

.overlay-content a:hover {
  color: red;
  text-decoration: underline;
  
}


.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hover-overlay img {
  max-width: 80%;
  max-height: 80%;
}

.grid-item:hover .hover-overlay {
  opacity: 1;
}

/* Video Grid */
.grid-container {
 display: grid;
 grid-template-columns: repeat(6, 1fr);
 grid-template-rows: repeat(4, 1fr);
 width: 100vw;
 height: 100vh;
 gap: 0;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 1;
}

.grid-item {
 position: relative;
 overflow: hidden;
}

video {
 display: block;
 width: 100%;
 height: 100%;
 object-fit: cover;
}

.hover-overlay {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background: rgba(0, 0, 0, 1); /* Optional dark background */
 display: flex;
 align-items: center;
 justify-content: center;
 opacity: 0;
 transition: opacity 0.3s ease-in-out;
}

.hover-overlay img {
 max-width: 80%;
 max-height: 80%;
}

.grid-item:hover .hover-overlay {
 opacity: 1;
}

/* Keyframes for Floating Video Animation */
@keyframes scale-to-500 {
 0% {
   transform: translate(-50%, -50%) scale(0);
 }
 100% {
   transform: translate(-50%, -50%) scale(6);
 }
}


/* Responsive Breakpoints for Grid */
@media (max-width: 1200px) { /* Large screens */
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) { /* Tablets */
  .grid-item video {
    position: fixed; /* Fixed to ensure it occupies the screen */
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    object-fit: cover; /* Ensure video covers the area without distortion */
    transform: scale(1.1); /* Optional: Zoom slightly for better coverage */
    z-index: 1; /* Ensures video stays on top */
  }

  .main-container {
    overflow: hidden; /* Prevent scrolling on mobile when video is fullscreen */
  }
  .mobile-only {
    display: block;
  }
}

@media (max-width: 480px) { /* Mobile devices */

 .floating-video {
   width: 90%; 
 }

 .show-key-button {
   right: 38%;
   bottom: 11%;
 }
 .overlay-content h1 {
  font-family: 'Eina-Bold', Arial, sans-serif;
  font-size: clamp(4.2rem, 5vw, 4rem);
}
.overlay-content h1 a {
  text-decoration: underline;
}
.overlay-content h2 {
  font-size: clamp(2.4rem, 5vw, 4rem);
  text-decoration: underline;
  line-height: 1.3em;
}

.overlay-content h3 {
  font-size: clamp(1rem, 4.4vw, 3rem);
  font-weight: bold;
  color: red;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-style: italic;
  line-height: 1em;
  margin-top: 1em;
  cursor: default;
}
.mobile-only {
  display: block;
}
.countdown {
  font-size: clamp(3rem, 5vw, 4rem); 
}
.alpha {
  font-size: 0.3em;
}

.meta {
  font-size: 0.4em;
  display: inline-block;
  transform: scaleX(1.5); /* Adjust this value */
}

.manifold {
  font-size: 0.3em;
}

.jpl {
  font-size: 0.4em;
  display: inline-block;
  transform: scaleX(2.2); /* Adjust this value */
}
.overlay-content p {
  font-size: 0.5rem;
}

.grid-item video {
  position: fixed; /* Fixed to ensure it occupies the screen */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Ensure video covers the area without distortion */
  transform: scale(1.1); /* Optional: Zoom slightly for better coverage */
  z-index: 1; /* Ensures video stays on top */
}

.main-container {
  overflow: hidden; /* Prevent scrolling on mobile when video is fullscreen */
}
 
 .floating-video {
   top: 45%;
}
/* Keyframes for Super Smooth Pulsing Scale Animation */
@keyframes scale-to-500 {
   0% {
     transform: translate(-50%, -50%) scale(0); /* Start at 0% */
   }
   100% {
     transform: translate(-50%, -50%) scale(6); /* Scale up to 600% */
   }
 }
}

